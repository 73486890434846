import { Button } from "@mui/material"
import React from 'react'
import { useDispatch } from "react-redux"
import { openPopUp } from "../../../actions/PopUpActions"
import StackHorizontal from "../../components/StackHorizontal"
import { Label } from "../../components/moduls/Input"
import POP_Comment from "./POP_Comment"


export default function CommentList({ Self, entity }) {
    const dispatch = useDispatch()
    return (<>
        <StackHorizontal>
            <Label>Комментарии</Label>
            <Button size='small' onClick={() => {
                dispatch(openPopUp(<POP_Comment entity={entity} id_entity={Self.id} />))
            }}>Добавить</Button>
        </StackHorizontal>
        {
            (Self.comments?.length || 0) == 0 ?
                <small>Нет комментариев</small>
                :
                Self.comments?.map(comment =>
                    <div key={'comment_' + comment.ID} onClick={() => {
                        dispatch(openPopUp(<POP_Comment ID_OBJECT={comment.ID} SelfData={comment} entity={entity} id_entity={Self.id} />))
                    }} style={{
                        cursor: 'pointer'
                    }}>
                        <small>{comment.RATING} / 5</small>
                        <p>{comment.TEXT?.substr(0, 50)}{comment.TEXT?.length > 50 && '...'}</p>
                    </div>
                )
        }
    </>)
}