
const initialState = {
  POPUPS: [],
  TEXTS: null,
  EXPOS: null,
  SHOP: null,
  PARTNERS: null,
  ORDERS: null,
  News: null
}//null - это пометка того, что это обязательные данные для старта системы


export function pageReducer(state = initialState, action) {
  switch (action.type) {


    case 'JUST_SET_VALUE':
      let temp_state = {}
      Object.assign(temp_state, state)
      temp_state[action.key] = action.payload

      return temp_state




    default:
      return state;
  }
}