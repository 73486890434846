import { Button } from "@mui/material";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { openPopUp } from "../../../actions/PopUpActions";
import StackHorizontal from "../../components/StackHorizontal";
import ItemList from "../Expos/ItemList";
import POP_Partner from './POP_Partner';

export default function Partners() {
    const dispatch = useDispatch()
    const PARTNERS = useSelector(store => store.page.PARTNERS)
    const openPartner = (id) => {
        dispatch(openPopUp(<POP_Partner ID_OBJECT={id} />))
    }


    return (<div>

        <StackHorizontal>
            <div style={{
                flex: 1
            }}>
                {PARTNERS.map(partner =>
                    <ItemList key={partner.id}
                        onClick={() => openPartner(partner.id)}
                        SelfData={partner}
                    />
                )}
            </div>

            <div>
                <Button onClick={() => openPartner()}>Добавить партнера</Button>
            </div>
        </StackHorizontal>

    </div>)
}