import { AddBoxOutlined } from "@mui/icons-material"
import { Chip } from "@mui/material"
import React from 'react'
import { GF } from "../../../GlobalFunctions"
import StackHorizontal from "../../components/StackHorizontal"

export default function ItemList({ SelfData, onClick, small }) {
    const isSmall = small === true
    const size = isSmall ? 40 : 100
    return (
        <div className="w-100" style={{
            cursor: 'pointer',
            position: 'relative'
        }} onClick={onClick}>
            <StackHorizontal>
                {!!SelfData.images?.[0] ?
                    <img style={{
                        height: size,
                        width: size,
                        borderRadius: 5,
                        objectFit: "cover",
                        backgroundColor: 'rgba(0,0,0,0.1)'
                    }} src={global.URL_host + 'img/' + SelfData.images?.[0]} />
                    :
                    <AddBoxOutlined />
                }
                <div style={{
                    flex: 1
                }}>
                    {!!SelfData.title &&
                        <h5 className="mb-0">{SelfData.title}</h5>
                    }

                    {!!SelfData.all_price &&
                        <>
                            <p>{SelfData.guid}</p>
                            <small className="d-block">{GF.makeCoast(SelfData.all_price)}руб</small>
                            <small className="d-block">{GF.makeCoast(SelfData.all_count)}шт</small>
                            <div style={{
                                position: 'absolute',
                                right: 20,
                                top: 0
                            }}>
                                {SelfData.is_closed == 1 ?
                                    <Chip color='default' label='Закрыт' /> :
                                    <Chip color='success' label='Активный' />
                                }
                            </div>
                        </>
                    }

                    {!!SelfData.descr &&
                        <small style={{ lineHeight: '14px' }} className="d-block">{SelfData.descr}</small>
                    }
                    {!!SelfData.descr_s &&
                        <small style={{ lineHeight: '14px' }} className="d-block">{SelfData.descr_s.substring(0, 80)}</small>
                    }

                    {!!SelfData.text &&
                        <small className="d-block" style={{ lineHeight: '14px' }}>{SelfData.text?.substring(0, 100)}</small>
                    }

                    {!!SelfData.price &&
                        <p>{GF.makeCoast(SelfData.price)} руб</p>
                    }

                    {!!SelfData.count &&
                        <p>{GF.makeCoast(SelfData.count)} шт</p>
                    }

                </div>
            </StackHorizontal>
            {!isSmall &&
                <hr />
            }
        </div>
    )
}