import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from "react-redux";
import useOrders from "../../../Hooks/Data/Orders/useOrders";
import { getOrders } from "../../../actions/PageActions";
import { openPopUp } from "../../../actions/PopUpActions";
import ItemList from "../Expos/ItemList";
import POP_Order from "./POP_Order";

export default function Orders() {
    const updateTimer = useRef(null)

    const dispatch = useDispatch()
    const Orders = useOrders()

    const openShopItem = useCallback((id) => {
        dispatch(openPopUp(<POP_Order ID_OBJECT={id} />))
    }, [])

    useEffect(() => {
        updateTimer.current = setInterval(() => {
            dispatch(getOrders())
        }, 5000)

        return () => {
            clearInterval(updateTimer.current)
        }
    }, [])


    return (<div>


        {Orders.map(item =>
            <ItemList key={item.id}
                onClick={() => openShopItem(item.id)}
                SelfData={item}
            />
        )}
    </div>)
}