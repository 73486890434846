import { Button } from "@mui/material";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { openPopUp } from "../../../actions/PopUpActions";
import StackHorizontal from "../../components/StackHorizontal";
import ItemList from "../Expos/ItemList";
import POP_shopItem from './POP_shopItem';


export default function Shop() {
    const dispatch = useDispatch()
    const SHOP = useSelector(store => store.page.SHOP)
    const openShopItem = (id) => {
        dispatch(openPopUp(<POP_shopItem ID_OBJECT={id} />))
    }


    return (<div>

        <StackHorizontal>
            <div style={{
                flex: 1
            }}>
                {SHOP.map(item =>
                    <ItemList key={item.id}
                        onClick={() => openShopItem(item.id)}
                        SelfData={item}
                    />
                )}
            </div>

            <div>
                <Button onClick={() => openShopItem()}>Добавить товар</Button>
            </div>
        </StackHorizontal>

    </div>)
}