import React from 'react';

import styled from 'styled-components';
import { closePopUp, openPopUp } from "../actions/PopUpActions";
import { loadUserData_FromStorage } from "../actions/UserAct";

import { Button, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { GlobalFunctions as GF } from '../GlobalFunctions';
import { POPbottom, POPscroll } from './PopUp';
import POP_Alert from './PopUp/Views/POP_Alert';

class POP_PasswodRecover extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            email: null
        }
        this.input = []
    }

    render() {
        const { state, props } = this
        return (<POP>
            <POPscroll bottom>
                <p>Для восстановления пароля, укажите E-mail на который был зарегистрирован аккаунт </p>
                <TextField
                    color='success'
                    type='email'
                    label='E-mail'
                    className='w-100 mt-3'
                    margin='dense'
                    variant="filled"
                    size='small'
                    value={state.email}
                    onChange={(e) =>
                        this.setState({ email: e.target.value })
                    }
                />
            </POPscroll>
            <POPbottom>
                <Button size='small' color='success' disabled={state.email == null || state.email?.length <= 3 || state.email.indexOf('@') == -1 || state.email.indexOf('.') == -1} onClick={() => {
                    GF.API_task({
                        operation: 'ressetPass',
                        block: 'Auth',
                        LOGIN: state.email
                    }).then(data => {
                        this.props.closePopUp()
                        this.props.openPopUp(<POP_Alert text={`Мы отправим пароль в течении 2х минут на ${state.email}, если найдем такой аккаунт`} buttons={[
                            <Button size='small' color='success' onClick={() => this.props.closePopUp()}>Хорошо, проверю почту</Button>
                        ]} />
                        )
                    })
                }}>Выслать пароль</Button>
            </POPbottom>
        </POP>)
    }
}


const POP = styled.div`
width:400px;
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp: (content) => dispatch(openPopUp(content)),
        closePopUp: (content) => dispatch(closePopUp(content)),
        loadUserData_FromStorage: (content) => dispatch(loadUserData_FromStorage(content)),
    };
};


const mapStateToProps = (store) => {

    return {
        page: store.page
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(POP_PasswodRecover)


