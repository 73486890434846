import React, { Component } from 'react';

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, LinearProgress } from '@mui/material';
import ruLocale from 'date-fns/locale/ru';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { GlobalFunctions as GF } from "../GlobalFunctions";
import Pallet from '../Pallet';
import { getDB_Data, getDB_Data_lazy, getDB_Data_lazy_notID, getExpos, getNews, getOrders, getPartners, getShop, getTexts } from '../actions/PageActions';
import { openPopImgs } from '../actions/PopUpActions';
import { loadUserData_FromStorage, loginIn } from '../actions/UserAct';
import "../global";
import Login from "./Login";
import Main from './Main';
import { Bg } from './PopUp';
import PopUpImgs from './PopUp/PopUpImgs';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stratAnim: false,
      appLoaded: false,
      nowLoading: 0,
      maxLoading: 100,
      nowWaiting: 'Авторизация',
      showLoadingScreen: true,
      haveReactError: false
    }
    this.updateTimer = null
    this.DataShedul = []

    global.afterSustemLoad = () => {
      //тут описывается то, что будет запущено при старте системы
      this.props.loginIn()
    }

    global.afterLoginned = async () => {
      let DataTables = ['TEXTS', 'EXPOS', 'SHOP', 'ORDERS']


      //тут описывается то, что будет запущено после авторизации
      try {

        switch (this.props.user.data.ROLE) {
          case 'ADMN':
            //DataTables.push()
            this.DataShedul = this.DataShedul.concat([
              () => this.props.dispatch(getTexts()),
              () => this.props.dispatch(getExpos()),
              () => this.props.dispatch(getShop()),
              () => this.props.dispatch(getPartners()),
              () => this.props.dispatch(getOrders()),
              () => this.props.dispatch(getNews())

            ])
            break;

        }


      } catch (e) { }

      this.DataShedul.forEach((ds, i) => {
        setTimeout(() => ds(), 10 * i)
      })


      let i = 0;
      while (i < DataTables.length) {
        let d = DataTables[i]
        this.setState({ nowWaiting: d, nowLoading: Math.floor(i / this.DataShedul.length * 100) })
        while (this.props.page[d] == null) {
          await GF.WaitingTimer(100)
        }
        await GF.WaitingTimer(100)

        i++
      }

      this.setState({ nowWaiting: null, nowLoading: 100 })

      setTimeout(() => {
        this.setState({ appLoaded: true }, () => {
          setTimeout(() => {
            this.setState({ showLoadingScreen: false })
          }, 600)
        })
      }, 500)



    }

    global.startShedul = () => {
      //тут описываются все события, которые должны быть запущены после успешной авторизации
    }

    props.loadUserData_FromStorage()




  }

  componentDidCatch(error, info) {
    if (window.location.href.indexOf('localhost') == -1) {
      GF.API_task({
        operation: 'logError',
        block: 'PublicFunctions',
        ERROR: error.toString(),
        INFO: info.componentStack,
        PLATFORM: 'web ' + GF.browserDetection(),
        LAST_ROUTE: window.location.href.replace('https://', ''),
        AppVersion: null
      })
    }

    this.setState({ haveReactError: true })
  }


  componentDidMount() {

    setTimeout(() => {
      this.setState({ stratAnim: true })
    }, 1000)

  }

  componentWillUnmount() {


    clearInterval(this.updateTimer)
  }



  render() {
    const { user, PopUps } = this.props
    const { appLoaded, haveReactError } = this.state
    let content = []

    if (haveReactError) {
      return (<div style={{
        padding: 30
      }}>
        <h1>Что-то пошло не так</h1>
        <p className='w-75'>Платформа находится на стадии разработки, и такие ситуации, к сожалению, могут происходить. Мы уже получили отчет об этой ошибке и обязательно ее исправим. Приносим свои извинения за неудобства</p>
        <Button color='success' onClick={() => {
          window.location.replace('../../../../../../')
        }}>Вернуться на главный экран</Button>
      </div>)
    }

    /*
     <LinearProgress style={{
              height:5
            }} color='success'/>
    */

    const loading =
      window.innerWidth < 900 ?
        (<LinearProgress style={{
          height: 5,
          width: '100%',
        }} color='success' variant="determinate" value={this.state.nowLoading} />)
        :
        (
          <div key='rootView' style={{
            opacity: this.state.appLoaded ? 0 : 1,
            transition: '.5s',
            zIndex: 999999,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            overflow: 'hidden'
          }}>

            <div style={{
              width: 300,
              position: 'fixed',
              left: '50vw',
              top: '50vh',
              transform: 'translate(-50%,-50%)',
              zIndex: 999,
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div style={{
                display: "flex",
                gap: 5,
                alignItems: "flex-end",
                alignSelf: "center"
              }}>
                <h1 className='m-0'>CMS</h1>
                <p className='m-0 mb-1'>system</p>
              </div>
              <LinearProgress style={{
                height: 5,
                width: '100%',
                marginTop: 20
              }} color='info' variant="determinate" value={this.state.nowLoading} />


              <small style={{
                textAlign: 'center',
                width: '100%',
                display: 'block',
                marginTop: 20
              }}>{this.state.nowWaiting || 'Приложение загружается'}</small>
            </div>
            <div style={{
              opacity: this.state.stratAnim ? 0.5 : 0,
              transition: '5s',
              position: 'fixed',
              width: '100vw',
              height: '100vw',
              left: this.state.stratAnim ? '-65vw' : '-90vw',
              bottom: this.state.stratAnim ? '-40vw' : '-95vw',
              borderRadius: 500,
              zIndex: -1,
              background: 'rgb(46,125,50)',
              background: `radial-gradient(circle, ${Pallet.Accent} 40%, rgba(255,255,255,1) 68%)`,
            }} />
          </div>
        )

    if (this.state.showLoadingScreen && (user.isLogined == null || user.isLogined == true)) {
      content.push(loading)
    }

    if (user.isLogined == null) {

    } else if (user.isLogined) {
      if (appLoaded)
        content.push(
          <div style={{
            position: "relative",
            zIndex: 0
          }}>
            <BrowserRouter key="mainRouter">
              <Switch>

                <Route path='/'>

                  <Main />
                </Route>
              </Switch>

            </BrowserRouter>
          </div>
        )
    } else {

      content.push(
        <Login />
      )
    }

    return (
      <ToastProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>

          {PopUps.length == 0 ? null : <Bg />}
          {PopUps.map(popUp => {
            return popUp
          })}

          {content}
          <PopUpImgs />
        </LocalizationProvider>
      </ToastProvider>

    )

  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (arg) => dispatch(arg),
    loadUserData_FromStorage: (arg) => dispatch(loadUserData_FromStorage(arg)),
    loginIn: (arg) => dispatch(loginIn(arg)),

    getDB_Data: (operation, tableName, JSONparseKey) => dispatch(getDB_Data(operation, tableName, JSONparseKey)),
    getDB_Data_lazy: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy(tableName, JSONparseKey)),
    getDB_Data_lazy_notID: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy_notID(tableName, JSONparseKey)),


    openPopImgs: (arg) => dispatch(openPopImgs(arg)),
  };
};


const mapStateToProps = (store) => {
  return {
    user: store.user,
    page: store.page,
    PopUps: store.popups.windows,
    TEXTS: store.page.TEXTS,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

