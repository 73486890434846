import { Button } from "@mui/material";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { openPopUp } from "../../../actions/PopUpActions";
import StackHorizontal from "../../components/StackHorizontal";
import ItemList from "./ItemList";
import POP_expo from "./POP_expo";

export default function Expos() {
    const dispatch = useDispatch()
    const EXPOS = useSelector(store => store.page.EXPOS)
    const openExpos = (id) => {
        dispatch(openPopUp(<POP_expo ID_OBJECT={id} />))
    }


    return (<div>

        <StackHorizontal>
            <div style={{
                flex: 1
            }}>
                {EXPOS.map(expo =>
                    <ItemList key={expo.id}
                        onClick={() => openExpos(expo.id)}
                        SelfData={expo}
                    />
                )}
            </div>

            <div>
                <Button onClick={() => openExpos()}>Добавить экспозицию</Button>
            </div>
        </StackHorizontal>

    </div>)
}