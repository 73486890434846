import { GlobalFunctions as GF } from "../GlobalFunctions"



export function JSONparse(data, key) {
  data.forEach((d, index) => {
    try {
      data[index][key] = JSON.parse(d[key] || [])
    } catch (e) { data[index][key] = [] }
  })
  return data
}

export function getTexts(arg) {
  return (dispatch) => {
    GF.API_task({
      operation: "getTexts",
      block: 'OutOfAuth'
    })
      .then(res => {

        const result = {}

        res.forEach(r => {
          result[r.NAME] = {
            ...r,
            TEXT: r.TEXT?.at(0) == '{' || r.TEXT?.at(0) == '[' ? JSON.parse(r.TEXT) : r.TEXT
          }
          delete (result[r.NAME].NAME)
        })

        dispatch({
          type: "JUST_SET_VALUE",
          key: 'TEXTS',
          payload: result
        })
      })
  }
}

export function getExpos(arg) {
  return (dispatch) => {
    GF.API_task({
      operation: "getExpos",
      block: 'OutOfAuth'
    })
      .then(res => {



        dispatch({
          type: "JUST_SET_VALUE",
          key: 'EXPOS',
          payload: res
        })
      })
  }
}


export function getOrders(arg) {
  return (dispatch) => {
    GF.API_task({
      operation: "getOrders",
    })
      .then(res => {
        dispatch({
          type: "JUST_SET_VALUE",
          key: 'ORDERS',
          payload: res
        })
      })
  }
}


export function getNews(arg) {
  return (dispatch) => {
    GF.API_task({
      operation: "getNews",
      block: 'OutOfAuth'
    })
      .then(res => {
        dispatch({
          type: "JUST_SET_VALUE",
          key: 'News',
          payload: res
        })
      })
  }
}

export function getShop(arg) {
  return (dispatch) => {
    GF.API_task({
      operation: "getShop",
      block: 'OutOfAuth'
    })
      .then(res => {



        dispatch({
          type: "JUST_SET_VALUE",
          key: 'SHOP',
          payload: res
        })
      })
  }
}


export function getPartners(arg) {
  return (dispatch) => {
    GF.API_task({
      operation: "getPartners",
      block: 'OutOfAuth'
    })
      .then(res => {

        dispatch({
          type: "JUST_SET_VALUE",
          key: 'PARTNERS',
          payload: res
        })
      })
  }
}


export function getDB_Data(operation, tableName, JSONparseKey) {
  return (dispatch) => {
    GF.API_task({
      operation
    }).then(data => {

      if (Array.isArray(JSONparseKey)) {

        JSONparseKey.forEach(key => {
          if (key != null)
            JSONparse(data, key)
        })

      }
      else
        if (JSONparseKey != null)
          JSONparse(data, JSONparseKey)

      dispatch({
        type: 'JUST_SET_VALUE',
        key: tableName,
        payload: data
      })
    })

  }
}

export function getDB_Data_lazy(tableName, JSONparseKey) {
  return (dispatch) => {
    GF.API_task({
      operation: 'getDataFromTable',
      Table: tableName
    }).then(data => {

      if (Array.isArray(JSONparseKey)) {

        JSONparseKey.forEach(key => {
          if (key != null)
            JSONparse(data, key)
        })

      }
      else
        if (JSONparseKey != null)
          JSONparse(data, JSONparseKey)

      dispatch({
        type: 'JUST_SET_VALUE',
        key: tableName,
        payload: data
      })
    })

  }
}

export function getDB_Data_lazy_notID(tableName, JSONparseKey) {
  return (dispatch) => {
    GF.API_task({
      operation: 'getDataFromTable',
      noSorting: true,
      Table: tableName
    }).then(data => {

      if (Array.isArray(JSONparseKey)) {

        JSONparseKey.forEach(key => {
          if (key != null)
            JSONparse(data, key)
        })

      }
      else
        if (JSONparseKey != null)
          JSONparse(data, JSONparseKey)

      dispatch({
        type: 'JUST_SET_VALUE',
        key: tableName,
        payload: data
      })
    })

  }
}



