import { Button, Stack, TextField } from "@mui/material"
import Immutable from "immutable/dist/immutable"
import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useDispatch } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { GF } from "../../../GlobalFunctions"
import useTexts from "../../../Hooks/useText"
import { getTexts } from "../../../actions/PageActions"
import Input_image from "../../components/Input_image"
import { Label } from "../../components/moduls/Input"

export default function TextsPage() {
    const TEXT = useTexts()
    const dispatch = useDispatch()
    const [texts, setText] = useState(TEXT)

    const [filesToUpload, setFilesToUpload] = useState([])


    const toast = useToasts()


    useLayoutEffect(() => {
        if (TEXT != texts)
            setText(TEXT)
    }, [TEXT])

    const save = useCallback(() => {

        if (filesToUpload.length > 0)
            GF.API_files({
                operation: 'setImageText',
            }, [...filesToUpload]).then(() => {
                dispatch(getTexts())
            })

        GF.API_task({
            operation: "updateTexts",
            data: { ...texts }
        }).then(res => {
            toast.addToast('Сохранено', { autoDismiss: true, appearance: 'success' })
            dispatch(getTexts())
        })

    }, [texts, filesToUpload])




    return (<div>

        <Stack flexDirection={'row'} justifyContent="space-between">
            <div>
                {Object.keys(TEXT).map(key =>
                    key == 'mainImg' ?
                        <div className='mb-2' style={{ height: 170 }}>
                            <Label>{TEXT[key].TITLE}</Label>
                            <Input_image multiple={false} value={[TEXT[key].TEXT]} onChange={(filesToUploadNew, filesToDelete) => {

                                setFilesToUpload([...filesToUploadNew])

                            }} />
                        </div> :
                        <div>
                            <TextField key={key}
                                multiline
                                rows={5}
                                value={texts[key].TEXT || ''}
                                onChange={e => {
                                    const tmp = Immutable.fromJS(texts).toJS()
                                    tmp[key].TEXT = e.target.value
                                    setText(tmp)
                                }}
                                label={TEXT[key].TITLE}
                                size="small"
                                style={{
                                    marginBottom: 20,
                                    width: 500,
                                }}
                            />
                        </div>
                )}
            </div>
            <div>
                <Button onClick={save}>СОхранить</Button>
            </div>
        </Stack>

    </div>)
}