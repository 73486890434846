import React from 'react';

import styled from 'styled-components';
import { openPopUp } from "../actions/PopUpActions";
import { loadUserData_FromStorage } from "../actions/UserAct";

import { Lock, Person } from '@mui/icons-material';
import { Alert, AlertTitle, Button, InputAdornment, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { down, up } from 'styled-breakpoints';
import BackImg from '../design/bg.jpg';
import { GlobalFunctions } from '../GlobalFunctions';
import Alert_pop from './PopUp/Views/POP_Alert';
import POP_PasswodRecover from './POP_PasswodRecover';

class Login extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            errorMSG: null,
            isLoaded: false,
            isLoadedALL: false
        }
        this.input = []
    }



    componentDidMount() {

        setTimeout(() => {
            this.setState({ isLoaded: true })
        }, 500)
    }

    passwordSendToMail() {
        if (!GlobalFunctions.checkReqvireds(this.input, ["PASSWORD"])) return


        GlobalFunctions.API_task({
            operation: "passwordSendToMail",
            LOGIN: encodeURIComponent(this.input.LOGIN.value)
        }).then(res => {
            if (res == "ok") {
                this.props.openPopUp(<Alert_pop text="Восстановленный пароль выслан на почту пользователя" />)
            }
        })
    }

    signIN() {

        this.setState({ errorMSG: null })

        if (!GlobalFunctions.checkReqvireds(this.input)) return

        GlobalFunctions.API_task({
            operation: "loginIn",
            block: 'Auth',
            password: encodeURIComponent(this.input.PASSWORD.value),
            login: encodeURIComponent(this.input.LOGIN.value)
        }).then(res => {
            if (res == null) {

                this.setState({ errorMSG: "Неверный логин или пароль" })
                return
            }
            this.setState({ isLoaded: false, isLoadedALL: true })
            setTimeout(() => {


                localStorage.setItem('login', res.LOGIN)
                localStorage.setItem('password', res.PASSWORD)
                localStorage.setItem('id', res.ID)




                this.props.loadUserData_FromStorage()
            }, 1000)
        }).catch((error) => {

            this.setState({ errorMSG: error.message })
        })
    }

    render() {
        const { } = this.props.page
        const { isLoaded, isLoadedALL } = this.state

        return (
            <BodyBlack isLoadedALL={isLoadedALL}>
                <Page className="Page Login bg-light">


                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 500,
                        margin: 'auto',
                        boxShadow: '0px 10px 30px rgba(0,0,0,0.07)',
                        marginTop: 20,
                        borderRadius: 10,
                        position: 'relative',
                        zIndex: 10,
                        padding: 20,
                        backgroundColor: "white",
                        maxWidth: 'calc(100vw - 40px)'
                    }}>
                        <div>
                            <h3 className='m-0'>CMS</h3>
                            <p className='m-0'>system</p>
                        </div>

                        <div>
                            <h5 className='m-0 text-end'>Вход</h5>
                            <p className='m-0 text-end'>в приложение</p>
                        </div>

                    </div>

                    <LoginView style={{
                        width: 500,
                        margin: 'auto',
                        boxShadow: '0px 10px 30px rgba(0,0,0,0.07)',
                        marginTop: 20,
                        borderRadius: 10,
                        padding: 20,
                        marginBottom: 150,
                        backgroundColor: "white",
                        maxWidth: 'calc(100vw - 40px)'
                    }} isLoaded={isLoaded} isLoadedALL={isLoadedALL}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: window.innerWidth < 500 ? 'wrap' : null,
                            gap: 20
                        }}>
                            <TextField
                                color='success'
                                label='Логин'
                                variant="filled"
                                size='small'
                                style={{
                                    minWidth: window.innerWidth < 500 ? 'calc(100vw - 80px)' : null
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Person />
                                        </InputAdornment>
                                    ),
                                }} inputRef={ref => this.input.LOGIN = ref}
                            />

                            <TextField
                                color='success'
                                label='Пароль'
                                type='password'
                                variant="filled"
                                style={{
                                    minWidth: window.innerWidth < 500 ? 'calc(100vw - 80px)' : null
                                }}
                                size='small'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Lock />
                                        </InputAdornment>
                                    ),
                                }} inputRef={ref => this.input.PASSWORD = ref}
                            />
                        </div>
                        <hr />
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 20
                        }}>
                            <Button color='inherit' size='small' onClick={() => {
                                this.props.openPopUp(<POP_PasswodRecover />)
                            }}>Восстановить пароль</Button>

                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: 20
                            }}>

                                <Button color='success' size='small' onClick={() => this.signIN()}>Вход</Button>
                            </div>
                        </div>
                        {this.state.errorMSG != null ?
                            <Alert severity="error" className='mt-3'>
                                <AlertTitle>Ошибка</AlertTitle>
                                {this.state.errorMSG}
                            </Alert>
                            : null}




                    </LoginView>
                </Page>
            </BodyBlack>
        )
    }
}



const LoginView = styled.div`

${props => props.isLoadedALL ? `transition:1s, opacity .3s;` : `transition:1s ease 1s , opacity .5s ease 1s;`}

${props => props.isLoaded ? `
transform:translateY(0px);
opacity:1;
`: `
transform:translateY(-100px);
opacity:0;
`}
`


const BodyBlack = styled.div`
height:100vh;
width:100vw;
${props => props.isLoadedALL ? 'background:rgba(0,0,0,0);' : 'background:rgba(0,0,0,1);'}
`



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp: (content) => dispatch(openPopUp(content)),
        loadUserData_FromStorage: (content) => dispatch(loadUserData_FromStorage(content)),
    };
};


const mapStateToProps = (store) => {

    return {
        page: store.page
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)

const BGimg = styled.div`
background:url('${BackImg}');
height:100vh;
    width:100vw;
    transition:1s;
    object-fit: cover;
    background-position: center top;
background-size: cover;
position: fixed;
    top:0;
    left:0;


${down('md')}{
    ${props => props.isLoaded ? `
    transform:scale(1);
    opacity:1;
    `: `
    transform:scale(1.5);
    opacity:0;
    `}
}

${up('md')}{
    ${props => props.isLoaded ? `
    transform:scale(1);
    opacity:1;
    `: `
    transform:scale(1.1);
    opacity:0;
    `}
}
`

const Page = styled.div`
    
   
    overflow: hidden;
    height:100vh;
    width:100vw;
    



`

const DarkBg = styled.div`
position:fixed;
background:rgba(0,0,0,0.);
top:0;
left:0px;
width:100vw;
height:100vh;
`
