import { Button, TextField } from "@mui/material"
import Immutable from "immutable/dist/immutable"
import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import Debounce from "../../../Class/Debounce"
import { GF } from "../../../GlobalFunctions"
import { getPartners } from "../../../actions/PageActions"
import { closePopUp } from "../../../actions/PopUpActions"
import { POPbottom, POPscroll, POPtop } from "../../PopUp"
import Input_image from "../../components/Input_image"
import { Label } from "../../components/moduls/Input"



const fields = [
    {
        label: 'Название',
        key: 'title'
    },
    {
        label: 'Короткое описание',
        key: 'descr_s',
        rows: 2,
        multiline: true
    },
    {
        label: 'Полное описание',
        rows: 6,
        key: 'descr_l',
        multiline: true
    },
]



export default function POP_Partner({ ID_OBJECT }) {

    const [Self, setSelf] = useState({})
    const toast = useToasts()

    const [filesToUpload, setFilesToUpload] = useState([])
    const [filesToDelete, setFilesToDelete] = useState([])
    const PARTNERS = useSelector(store => store.page.PARTNERS)

    useLayoutEffect(() => {
        setSelf(
            ID_OBJECT == null ? {} :
                Immutable.fromJS(PARTNERS.find(e => e.id == ID_OBJECT)).toJS()
        )
    }, [PARTNERS, ID_OBJECT])

    const dispatch = useDispatch()



    const deleteIt = useCallback(() => {
        dispatch(closePopUp())
        GF.API_task({
            operation: "deleteSome",
            Table: "PARTNERS",
            ID: Self.id,
        }).then(res => {
            dispatch(getPartners())
            toast.addToast('Партнер удален', { autoDismiss: true, appearance: 'warning' })
        })
    }, [Self])

    const save = useCallback(() => {
        Debounce.start(() => {
            if (filesToUpload.length == 0 && !ID_OBJECT) {
                alert('Нужно выбрать изображение')
                return
            }

            const temp = {}
            fields.forEach(f => {
                temp[f.key] = Self[f.key]
            })

            GF.API_task({
                operation: "createUpdateSome",
                Table: "PARTNERS",
                ID: Self.id,
                data: temp
            }).then(res => {

                if (filesToUpload.length > 0)
                    GF.API_files({
                        operation: 'setIMAGE',
                        Table: "PARTNERS",
                        OtherFieldName: "images",
                        ID: res.id
                    }, [...filesToUpload]).then(() => {
                        setFilesToUpload([])
                        dispatch(getPartners())
                    })

                if (filesToDelete.length > 0)
                    GF.API_task({
                        operation: 'deleteIMAGE',
                        ID: res.id,
                        Table: 'PARTNERS',
                        OtherFieldName: "images",
                        filesToDelete: JSON.stringify(filesToDelete)
                    }).then(() => {
                        setFilesToDelete([])
                        dispatch(getPartners())
                    })


                toast.addToast('Сохранено', { autoDismiss: true, appearance: 'success' })
                dispatch(getPartners())
                dispatch(closePopUp())
            })
        })
    }, [Self, filesToUpload, filesToDelete])

    if (Self.id == null && ID_OBJECT != null) return <div style={{ width: 400 }} />

    return (<div style={{
        width: 400
    }}>
        <POPtop>
            <h3>Партнер</h3>
        </POPtop>

        <POPscroll top bottom>
            {fields.map(f =>
                <div>
                    <TextField
                        {...f}
                        value={Self[f.key]}
                        onChange={e => {
                            const tmp = Immutable.fromJS(Self).toJS()
                            tmp[f.key] = e.target.value
                            setSelf(tmp)
                        }}
                        size="small"
                        style={{
                            marginBottom: 20,
                            width: '100%'
                        }}
                    />
                </div>
            )}

            <div className='mb-2' style={{ height: 170 }}>
                <Label>Изображение</Label>
                <Input_image multiple={false} value={Self.images || []} onChange={(filesToUploadNew, filesToDeleteNew) => {

                    setFilesToUpload([...filesToUpload, ...filesToUploadNew])
                    setFilesToDelete([...filesToDelete, ...filesToDeleteNew])

                }} />
            </div>

        </POPscroll>
        <POPbottom>
            {Self.id == null ? <div /> :
                <Button color='error' onClick={deleteIt}>Удалить</Button>
            }
            <Button onClick={save}>Сохранить</Button>
        </POPbottom>
    </div>)
}