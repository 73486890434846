import Immutable from "immutable";
import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useOrders() {
    const SHOP = useSelector(store => store.page.SHOP)
    const ORDERS = useSelector(store => store.page.ORDERS)

    const [Orders, setOrders] = useState([])

    useLayoutEffect(() => {
        const newOrders = Immutable.fromJS(ORDERS).toJS()

        newOrders.forEach((order, i) => {
            order.items?.forEach((item, t) => {
                newOrders[i].items[t] = {
                    ...item,
                    ...(SHOP.find(sh => sh.id == item.id_shop_item) || {
                        images: [],
                        title: '404'
                    })
                }
            })
        })

        setOrders(newOrders)

    }, [SHOP, ORDERS])

    return Orders
}