import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../actions/PopUpActions';
import LeftMenu from './components/LeftMenu';
import TopMenu from './components/TopMenu';



class Main extends React.PureComponent {
  constructor(props) {
    super()
    this.state = {
      errorMSG: null,
    }
    this.input = []
  }



  componentDidMount() {

  }


  render() {

    return (
      <MainView>

        <TopMenu />
        <LeftMenu />

      </MainView>
    )
  }
}

const MainView = styled.div`
background:rgb(232, 236, 238);
width:100vw;
height:100vh;
overflow:hidden;
`
const ContentBlock = styled.div`
margin-top:50px;
`

const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (arg) => dispatch(openPopUp(arg))
  };
};


const mapStateToProps = (store) => {

  return {
    page: store.page
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

