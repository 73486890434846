import { Button, TextField } from "@mui/material"
import Immutable from "immutable/dist/immutable"
import { QRCodeSVG } from 'qrcode.react'
import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import Debounce from "../../../Class/Debounce"
import { GF } from "../../../GlobalFunctions"
import { getExpos } from "../../../actions/PageActions"
import { closePopUp, openPopUp } from "../../../actions/PopUpActions"
import { POPbottom, POPscroll, POPtop } from "../../PopUp"
import Input_image from "../../components/Input_image"
import StackHorizontal from "../../components/StackHorizontal"
import { Label } from "../../components/moduls/Input"
import CommentList from "./CommentList"
import ItemList from "./ItemList"
import POP_expoItem from "./POP_expoItem"

const fields = [
    {
        label: 'Название',
        key: 'title'
    },
    {
        label: 'Рейтинг',
        key: 'rating',
        type: "number"
    },
    {
        label: 'Короткое описание',
        key: 'descr',
        rows: 2,
        multiline: true
    },
    {
        label: 'Полное описание',
        rows: 6,
        key: 'text',
        multiline: true
    },
]



export default function POP_expo({ ID_OBJECT }) {

    const [Self, setSelf] = useState({})
    const toast = useToasts()

    const [filesToUpload, setFilesToUpload] = useState([])
    const [filesToDelete, setFilesToDelete] = useState([])
    const EXPOS = useSelector(store => store.page.EXPOS)

    useLayoutEffect(() => {
        setSelf(
            ID_OBJECT == null ? {} :
                Immutable.fromJS(EXPOS.find(e => e.id == ID_OBJECT)).toJS()
        )
    }, [EXPOS, ID_OBJECT])


    const downloadQR = () => {
        const img = new Image(),
            serializer = new XMLSerializer(),
            svgStr = serializer.serializeToString(document.getElementById('qrCode'));

        img.src = 'data:image/svg+xml;base64,' + window.btoa(svgStr);
        var canvas = document.createElement("canvas");

        var w = 400;
        var h = 400;

        canvas.width = w;
        canvas.height = h;
        canvas.getContext("2d").drawImage(img, 0, 0, w, h);

        var imgURL = canvas.toDataURL("image/png");


        var dlLink = document.createElement('a');
        dlLink.download = "image";
        dlLink.href = imgURL;
        dlLink.dataset.downloadurl = ["image/png", dlLink.download, dlLink.href].join(':');

        document.body.appendChild(dlLink);
        dlLink.click();
        document.body.removeChild(dlLink);
    }

    const dispatch = useDispatch()

    const openExpoItem = (id) => {
        dispatch(openPopUp(<POP_expoItem ID_PARENT={Self.id} ID_OBJECT={id} />))
    }

    const deleteIt = useCallback(() => {
        dispatch(closePopUp())
        GF.API_task({
            operation: "deleteSome",
            Table: "EXPOS",
            ID: Self.id,
        }).then(res => {
            dispatch(getExpos())
            toast.addToast('Экспозиция удалена', { autoDismiss: true, appearance: 'warning' })
        })
    }, [Self])

    const save = useCallback(() => {
        Debounce.start(() => {
            if (filesToUpload.length == 0 && !ID_OBJECT) {
                alert('Нужно выбрать хотябы 1 изображение')
                return
            }

            const temp = {}
            fields.forEach(f => {
                temp[f.key] = Self[f.key]
            })

            GF.API_task({
                operation: "createUpdateSome",
                Table: "EXPOS",
                ID: Self.id,
                data: temp
            }).then(res => {

                if (filesToUpload.length > 0)
                    GF.API_files({
                        operation: 'setIMAGE',
                        Table: "EXPOS",
                        OtherFieldName: "images",
                        ID: res.id
                    }, [...filesToUpload]).then(() => {
                        setFilesToUpload([])
                        dispatch(getExpos())
                    })

                if (filesToDelete.length > 0)
                    GF.API_task({
                        operation: 'deleteIMAGE',
                        ID: res.id,
                        Table: 'EXPOS',
                        OtherFieldName: "images",
                        filesToDelete: JSON.stringify(filesToDelete)
                    }).then(() => {
                        setFilesToDelete([])
                        dispatch(getExpos())
                    })


                toast.addToast('Сохранено', { autoDismiss: true, appearance: 'success' })
                dispatch(getExpos())
                dispatch(closePopUp())
            })
        })
    }, [Self, filesToUpload, filesToDelete])

    if (Self.id == null && ID_OBJECT != null) return <div style={{ width: 800 }} />

    return (<div style={{
        width: 800
    }}>
        <POPtop>
            <h3>Экспозиция</h3>
        </POPtop>
        <POPscroll top bottom style={{ paddingBottom: 200 }}>


            <StackHorizontal>
                <div style={{
                    flex: 1
                }}>
                    <QRCodeSVG size={100} style={{
                        marginBottom: 20,
                        marginRight: 20
                    }} id="qrCode" renderAs='canvas' value={JSON.stringify({
                        'Museum': {
                            expos_id: Self.id
                        }
                    })} />
                    <Button onClick={downloadQR}>Скачать</Button>
                    {fields.map(f =>
                        <div>
                            <TextField
                                {...f}
                                value={Self[f.key]}
                                onChange={e => {
                                    const tmp = Immutable.fromJS(Self).toJS()
                                    tmp[f.key] = e.target.value
                                    setSelf(tmp)
                                }}
                                size="small"
                                style={{
                                    marginBottom: 20,
                                    width: '100%'
                                }}
                            />
                        </div>
                    )}

                    <div className='mb-2' style={{ height: 170 }}>
                        <Label>Изображения</Label>
                        <Input_image multiple={true} value={Self.images || []} onChange={(filesToUploadNew, filesToDeleteNew) => {

                            setFilesToUpload([...filesToUpload, ...filesToUploadNew])
                            setFilesToDelete([...filesToDelete, ...filesToDeleteNew])

                        }} />
                    </div>

                    <hr />

                    <CommentList entity='EXPO' Self={Self} />
                </div>

                <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: "flex-end",
                    alignItems: 'flex-end'
                }}>
                    <Button disabled={!Self.id} onClick={() => openExpoItem()}>Добавить экспонат</Button>
                    {!Self.id &&
                        <small>Чтобы добавить экспонаты, сохраните экспозицию</small>
                    }

                    <div style={{
                        flex: 1,
                        width: '100%',
                        overflow: "auto"
                    }}>

                        {Self.items?.map(itm =>
                            <ItemList key={itm.id}
                                onClick={() => openExpoItem(itm.id)}
                                SelfData={itm}
                            />
                        )}
                    </div>
                </div>
            </StackHorizontal>
        </POPscroll>
        <POPbottom>


            {Self.id == null ? <div /> :
                <Button color='error' onClick={deleteIt}>Удалить</Button>
            }
            <Button onClick={save}>Сохранить</Button>


        </POPbottom>
    </div>)
}