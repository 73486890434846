import { Button, Chip } from "@mui/material"
import Immutable from "immutable/dist/immutable"
import { useCallback, useLayoutEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useToasts } from "react-toast-notifications"
import Debounce from "../../../Class/Debounce"
import { GF } from "../../../GlobalFunctions"
import useOrders from "../../../Hooks/Data/Orders/useOrders"
import { getOrders } from "../../../actions/PageActions"
import { closePopUp } from "../../../actions/PopUpActions"
import { POPbottom, POPscroll, POPtop } from "../../PopUp"
import StackHorizontal from "../../components/StackHorizontal"
import { Label } from "../../components/moduls/Input"

import React from 'react'


const fields = [
    {
        label: 'Название',
        key: 'guid'
    },
    {
        label: 'Цена',
        postfix: ' руб',
        type: 'number',
        key: 'all_price'
    },
    {
        label: 'Количество товаров',
        type: 'number',
        postfix: ' шт',
        key: 'all_count',
    },
    {
        label: 'Количество наименований',
        type: 'number',
        postfix: ' шт',
        key: 'items_count',
    },

    {
        label: 'ФИО',
        key: 'fio',
    },
    {
        label: 'Телефон',
        key: 'phone',
    },
    {
        label: 'E-mail',
        key: 'email',
    },
]



export default function POP_Order({ ID_OBJECT }) {

    const [Self, setSelf] = useState({})
    const toast = useToasts()
    const Orders = useOrders()


    useLayoutEffect(() => {
        setSelf(
            ID_OBJECT == null ? {} :
                Immutable.fromJS(Orders.find(e => e.id == ID_OBJECT))?.toJS() || {}
        )
    }, [Orders, ID_OBJECT])

    const dispatch = useDispatch()



    const deleteIt = useCallback(() => {
        Debounce.start(() => {
            dispatch(closePopUp())
            GF.API_task({
                operation: "deleteSome",
                Table: "ORDERS",
                ID: Self.id,
            }).then(res => {
                dispatch(getOrders())

                toast.addToast('Заказ удален', { autoDismiss: true, appearance: 'warning' })
            })
        })
    }, [Self])

    const save = useCallback(() => {
        Debounce.start(() => {
            GF.API_task({
                operation: "closeOrder",
                ID: Self.id,
            }).then(res => {

                toast.addToast('Заказ завершен', { autoDismiss: true, appearance: 'success' })
                dispatch(getOrders())
                dispatch(closePopUp())
            })
        })
    }, [Self])

    if (Self.id == null && ID_OBJECT != null) return <div style={{ width: 400 }} />

    return (<div style={{
        width: 400
    }}>
        <POPtop>
            <h3>Заказ</h3>
            <Chip label={'#' + Self.guid} />
        </POPtop>

        <POPscroll top bottom>
            {fields.map(f =>
                <div>
                    <Label>{f.label}</Label>
                    <p>{f.type == 'number' ? GF.makeCoast(Self[f.key]) : Self[f.key]}{f.postfix}</p>
                </div>
            )}

            {Self.items.map(item =>
                <div>
                    <StackHorizontal>
                        <img style={{
                            height: 40,
                            width: 40,
                            borderRadius: 3,
                            objectFit: "cover",
                            backgroundColor: 'rgba(0,0,0,0.1)'
                        }} src={global.URL_host + 'img/' + item.images?.[0]} />
                        <div style={{
                            flex: 1
                        }}>
                            <p className="m-0">{item.title}</p>
                            <small className="m-0 d-block">{GF.makeCoast(item.price)} руб</small>
                        </div>
                        <div>
                            <Chip size='small' label={GF.makeCoast(item.price * item.count) + ' руб'} />
                            <small className="m-0 d-block w-100 text-center">{GF.makeCoast(item.count)} шт</small>
                        </div>
                    </StackHorizontal>
                </div>
            )}
        </POPscroll>


        <POPbottom>
            <StackHorizontal>
                {Self.id == null ? <div /> :
                    <Button color='error' onClick={deleteIt}>Удалить</Button>
                }
                <Button onClick={save}>Завершить</Button>
            </StackHorizontal>
        </POPbottom>

    </div>)
}