import { Avatar, Chip, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { openPopUp } from '../../actions/PopUpActions';
import { loadUserData_FromStorage, logOut } from '../../actions/UserAct';

import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';

class TopMenu extends React.PureComponent {
    constructor(props) {
        super()
        this.state = {
            errorMSG: null,
        }

    }



    componentDidMount() {

    }

    exitAk() {
        const { cookies } = this.props

        localStorage.removeItem('login')
        localStorage.removeItem('password')
        localStorage.removeItem('id')

        this.props.logOut()
        this.props.loadUserData_FromStorage()
    }


    render() {
        const { user, isLogined } = this.props
        return (
            <MasterView>

                <h3 className='m-2'>CMS</h3>
                {!isLogined ? null : <>



                    <Chip onClick={e => this.setState({ anchorEl: e.currentTarget })}
                        avatar={user.IMAGES_URL == null ? <Avatar>{user.LOGIN[0]}</Avatar> :
                            <Avatar src={user.IMAGES_URL[0]} alt={user.LOGIN[0]} />
                        }
                        label={user.LOGIN}
                        style={{
                            position: 'absolute',
                            bottom: 10,
                            right: 20
                        }} />

                    <Menu
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        onClose={() => this.setState({ anchorEl: null })}>


                        <MenuItem onClick={() => {
                            this.setState({ anchorEl: null })
                            this.exitAk()
                        }}>
                            <ExitToAppRoundedIcon fontSize='small' style={{ marginRight: 10 }} />
                            Выход</MenuItem>
                    </Menu>

                </>}
            </MasterView>
        )
    }
}



const MasterView = styled.div`
height:50px;
width:100vw;
background:white;
box-shadow:10px 10px 30px rgba(0,0,0,0.03);
position:fixed;
top:0px;
left:0px;
z-index:10;
`
const Logo = styled.img`
height: 35px;
margin-top: 5px;
margin-left: 10px;
float:left;

${down('md')}{
    height: 26px;
    margin-top: 9px;
    margin-left: 70px;
}
`



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp: (arg) => dispatch(openPopUp(arg)),
        logOut: (arg) => dispatch(logOut(arg)),
        loadUserData_FromStorage: (arg) => dispatch(loadUserData_FromStorage(arg)),
    };
};


const mapStateToProps = (store) => {

    return {
        page: store.page,
        user: store.user.data,
        isLogined: store.user.isLogined,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopMenu));

