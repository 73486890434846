import { Button, TextField } from '@mui/material'
import Immutable from 'immutable'
import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import Debounce from '../../../Class/Debounce'
import { GF } from '../../../GlobalFunctions'
import { getExpos } from '../../../actions/PageActions'
import { closePopUp } from '../../../actions/PopUpActions'
import { POPbottom, POPscroll, POPtop } from '../../PopUp'

const fields = [
    {
        label: 'Текст',
        key: 'TEXT',
        rows: 5,
        multiline: true
    },
    {
        label: 'Рейтинг',
        key: 'RATING',
        type: "number"
    },
]



export default function POP_Comment({ ID_OBJECT, entity, id_entity, SelfData }) {
    const [Self, setSelf] = useState({})
    const dispatch = useDispatch()
    const toast = useToasts()


    useLayoutEffect(() => {
        if (SelfData?.ID != Self.ID)
            setSelf(SelfData)
    }, [SelfData])

    const deleteIt = useCallback(() => {
        dispatch(closePopUp())
        GF.API_task({
            operation: "deleteSome",
            Table: "COMMENTS",
            ID: Self.ID,
        }).then(res => {
            dispatch(getExpos())
            toast.addToast('Отзыв удален', { autoDismiss: true, appearance: 'warning' })
        })
    }, [Self])

    const save = useCallback(() => {
        Debounce.start(() => {

            if (Self.RATING < 1 || Self.RATING > 5 || Self.RATING == null || Self.RATING == '') {
                alert('Нужно указать рейтинг')
                return
            }


            let temp = {}
            fields.forEach(f => {
                temp[f.key] = Self[f.key]
            })

            temp = {
                ...temp,
                ENTITY: entity,
                ID_ENTITY: id_entity
            }

            GF.API_task({
                operation: "createUpdateSome",
                Table: "COMMENTS",
                ID: Self.ID || ID_OBJECT || null,
                data: temp
            }).then(res => {

                toast.addToast('Сохранено', { autoDismiss: true, appearance: 'success' })
                dispatch(getExpos())
                dispatch(closePopUp())
            })
        })
    }, [Self, ID_OBJECT])


    if (Self.ID == null && ID_OBJECT != null) return <div style={{ width: 500 }} />

    return (<div style={{ width: 500 }}>
        <POPtop>
            <h5>Отзыв</h5>
        </POPtop>
        <POPscroll top bottom>
            {fields.map(f =>
                <div>
                    <TextField
                        {...f}
                        value={Self[f.key]}
                        onChange={e => {
                            const tmp = Immutable.fromJS(Self).toJS()
                            tmp[f.key] = e.target.value
                            setSelf(tmp)
                        }}
                        size="small"
                        style={{
                            marginBottom: 20,
                            width: '100%'
                        }}
                    />
                </div>
            )}
        </POPscroll>
        <POPbottom>
            {Self.ID == null ? <div /> :
                <Button color='error' onClick={deleteIt}>Удалить</Button>
            }
            <Button onClick={save}>Сохранить</Button>
        </POPbottom>
    </div>)
}