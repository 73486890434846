import { Button, TextField } from "@mui/material"

import { DatePicker } from "@mui/lab"
import Immutable from "immutable/dist/immutable"
import React, { useCallback, useLayoutEffect, useState } from 'react'
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import Debounce from "../../../Class/Debounce"
import { GF } from "../../../GlobalFunctions"
import { getNews } from "../../../actions/PageActions"
import { closePopUp } from "../../../actions/PopUpActions"
import { POPbottom, POPscroll, POPtop } from "../../PopUp"
import Input_image from "../../components/Input_image"
import StackHorizontal from "../../components/StackHorizontal"
import { Label } from "../../components/moduls/Input"

const fields = [
    {
        label: 'Заголовок',
        key: 'title'
    },
]



export default function POP_News({ ID_OBJECT }) {
    const [filesToUpload, setFilesToUpload] = useState([])
    const [filesToDelete, setFilesToDelete] = useState([])
    const [Self, setSelf] = useState({})
    const toast = useToasts()
    const News = useSelector(store => store.page.News)


    useLayoutEffect(() => {
        setSelf(
            ID_OBJECT == null ? {} :
                Immutable.fromJS(News.find(e => e.id == ID_OBJECT))?.toJS() || {}
        )
    }, [News, ID_OBJECT])

    const dispatch = useDispatch()

    const setValue = useCallback((text) => {
        setSelf(prev => ({
            ...prev,
            text
        }))
    }, [])


    const setDateView = useCallback((date_view) => {
        setSelf(prev => ({
            ...prev,
            date_view
        }))
    }, [])



    const deleteIt = useCallback(() => {
        Debounce.start(() => {
            dispatch(closePopUp())
            GF.API_task({
                operation: "deleteSome",
                Table: "NEWS",
                ID: Self.id,
            }).then(res => {
                dispatch(getNews())

                toast.addToast('Новость удалена', { autoDismiss: true, appearance: 'warning' })
            })
        })
    }, [Self])

    const save = useCallback(() => {
        Debounce.start(() => {
            if (filesToUpload.length == 0 && !ID_OBJECT) {
                alert('Нужно выбрать хотябы 1 изображение')
                return
            }

            const temp = {}
            fields.forEach(f => {
                temp[f.key] = Self[f.key]
            })

            temp.text = Self.text
            temp.date_view = Self.date_view

            GF.API_task({
                operation: "createUpdateSome",
                Table: "NEWS",
                ID: Self.id,
                data: temp
            }).then(res => {

                if (filesToUpload.length > 0)
                    GF.API_files({
                        operation: 'setIMAGE',
                        Table: "NEWS",
                        OtherFieldName: "images",
                        ID: res.id
                    }, [...filesToUpload]).then(() => {
                        setFilesToUpload([])
                        dispatch(getNews())
                    })

                if (filesToDelete.length > 0)
                    GF.API_task({
                        operation: 'deleteIMAGE',
                        ID: res.id,
                        Table: 'NEWS',
                        OtherFieldName: "images",
                        filesToDelete: JSON.stringify(filesToDelete)
                    }).then(() => {
                        setFilesToDelete([])
                        dispatch(getNews())
                    })


                toast.addToast('Сохранено', { autoDismiss: true, appearance: 'success' })
                dispatch(getNews())
                dispatch(closePopUp())
            })
        })
    }, [Self, filesToUpload, filesToDelete])

    if (Self.id == null && ID_OBJECT != null) return <div style={{ width: 400 }} />

    return (<div style={{
        width: 400
    }}>
        <POPtop>
            <h3>Запись</h3>
        </POPtop>

        <POPscroll top bottom>
            {fields.map(f =>
                <div>
                    <TextField
                        {...f}
                        value={Self[f.key]}
                        onChange={e => {
                            const tmp = Immutable.fromJS(Self).toJS()
                            tmp[f.key] = e.target.value
                            setSelf(tmp)
                        }}
                        size="small"
                        style={{
                            marginBottom: 20,
                            width: '100%'
                        }}
                    />
                </div>
            )}

            <DatePicker
                label="Дата записи"
                value={Self.date_view}
                onChange={setDateView}
                renderInput={(params) => <TextField size="small" className="w-100 mb-3" {...params} />}
            />

            <div className='mb-2' style={{ height: 170 }}>
                <Label>Изображение</Label>
                <Input_image multiple={false} value={Self.images || []} onChange={(filesToUploadNew, filesToDeleteNew) => {

                    setFilesToUpload([...filesToUpload, ...filesToUploadNew])
                    setFilesToDelete([...filesToDelete, ...filesToDeleteNew])

                }} />
            </div>

            <ReactQuill theme="snow" value={Self.text || ''} onChange={setValue} />

        </POPscroll>


        <POPbottom>
            <StackHorizontal>
                {Self.id == null ? <div /> :
                    <Button color='error' onClick={deleteIt}>Удалить</Button>
                }
                <Button onClick={save}>Сохранить</Button>
            </StackHorizontal>
        </POPbottom>

    </div>)
}