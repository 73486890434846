global.URL_host = 'https://empty-museum.lpn.su/'
global.URL_server = global.URL_host + 'server/'
global.URL_API = global.URL_server + 'task.php?'

global.afterSustemLoad = () => {
  //тут описывается то, что будет запущено при старте системы (но из App.js)
}
global.startShedul = () => {
  //тут описываются все события, которые должны быть запущены после успешной авторизации (но из App.js)
}


