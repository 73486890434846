import { Button } from "@mui/material";
import moment from "moment";
import 'moment/locale/ru';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { openPopUp } from "../../../actions/PopUpActions";
import StackHorizontal from "../../components/StackHorizontal";
import ItemList from "../Expos/ItemList";
import POP_News from "./POP_News";

export default function News() {


    const dispatch = useDispatch()
    const News = useSelector(store => store.page.News)

    const openShopItem = useCallback((id) => {
        dispatch(openPopUp(<POP_News ID_OBJECT={id} />))
    }, [])



    return (<div>
        <StackHorizontal>
            <div style={{
                flex: 1
            }}>
                {News.map(item =>
                    <ItemList key={item.id}
                        onClick={() => openShopItem(item.id)}
                        SelfData={{
                            ...item,
                            text: null,
                            descr: moment(item.date_view).locale('ru').format('D MMMM YYYY')
                        }}
                    />
                )}
            </div>
            <div>
                <Button onClick={() => openShopItem()}>Добавить запись</Button>
            </div>
        </StackHorizontal>
    </div>)
}